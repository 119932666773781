import { Pipe, PipeTransform } from '@angular/core';
import { UserProfileModel } from '@shared/models/user-profile.model';
import { IUserShort } from '@models/user';
import { UserModel } from '@shared/models/user.model';

@Pipe({ name: 'userName', standalone: true })
export class UserNamePipe implements PipeTransform {
  transform(user: UserModel | IUserShort | UserProfileModel): string {
    if (!user) {
      return '';
    } else if (user.Nickname) {
      return user.Nickname;
    } else if (user.FirstName && user.LastName) {
      return `${user.FirstName} ${user.LastName}`;
    } else if ('UserId' in user && user.UserId === null) {
      return 'Guest';
    }
    return '';
  }
}
